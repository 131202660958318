import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import ToolsNPortals from "../components/Solutions/ToolsNPortals/ToolsNPortals";
import OurOfferings from "../components/Solutions/OurOfferings/OurOfferings";
import ToolsNAccelerators from "../components/Solutions/ToolsNAccelerators_ToolsNPortals/ToolsNAccelerators";
import OurProjects from "../components/Solutions/OurProjects_MobileSolutions/OurProjects";
import OurPerspectives from "../components/Solutions/OurPerspectives/OurPerspectives";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import { Helmet } from "react-helmet";
function supportToolsNPortals() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout> <Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies -  Support Tools and Portals       </title>
       <meta
         name="description"
         content="Secure your customer’s loyalty with an effortless support experience."
       ></meta></Helmet>
      <SolutionsHelmet />
      <ToolsNPortals />
      <OurOfferings />
      <ToolsNAccelerators />
      <OurProjects />
      <OurPerspectives />
      <LearnAboutUs page="support-tools-portals"/>
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default supportToolsNPortals;
