import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function OurOfferings() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--offerings section--dots-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Our Offerings</h2>
          </div>
        </div>
        <div className="container__row">
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/offerings/process.png"
                alt="Process"
              />
              <h5 className="card__title">Process</h5>
              <div className="card__description">
                <h7>
                  Get support and guidance in implementing industry-leading
                  practices like:
                </h7>
                <ul>
                  <li>Knowledge Centered Services</li>
                  <li>SEO</li>
                  <li>Gamification</li>
                  <li>Content Gap Analysis</li>
                  <li>Support Community</li>
                </ul>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/offerings/tools-platforms.png"
                alt="Tools and Platforms"
              />
              <h5 className="card__title">Tools and Platforms</h5>
              <div className="card__description">
                <h7>
                  Partnerships and implementation experience with className-leading
                  products:
                </h7>
                <ul>
                  <li>Salesforce</li>
                  <li>Coveo</li>
                  <li>LMI - Bold360</li>
                  <li>AEM</li>
                  <li>Algolia</li>
                  <li>Google Cloud Search and Custom Search</li>
                  <li>Machine Translation (Azure and Google)</li>
                </ul>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                Learn more
              </a>
            </div>
          </div>
        </div>

        <div className="container__row">
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/offerings/development-support.png"
                alt="Development Support"
              />
              <h5 className="card__title">Development Support</h5>
              <div className="card__description">
                <h7>
                  Strong engineering capability to deliver end-to-end custom
                  solutions or specific modules on a wide variety of technology
                  solutions. Our Agile development pods include:
                </h7>
                <ul>
                  <li>UX</li>
                  <li>Architect</li>
                  <li>Developer</li>
                  <li>QA</li>
                  <li>Analyst/Product Manager</li>
                  <li>Project Manager</li>
                </ul>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="container__col-md-6">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/offerings/consulting-packages.png"
                alt="Consulting Packages"
              />
              <h5 className="card__title">Consulting Packages</h5>
              <div className="card__description">
                <h7>
                  Accelerator consulting packages to turn around performance and
                  realize ROI:
                </h7>
                <ul>
                  <li>
                    ROI-focused support experience and processes assessment.
                  </li>
                  <li>Usability Testing and Optimisation</li>
                  <li>Self-service success (Deflection) Measurement</li>
                  <li>Chatbot content strategy</li>
                  <li>SEO</li>
                  <li>CSAT and CES Analysis and action plan</li>
                </ul>
              </div>
              <a
                className="card__action --action-link"
                href="javascript:void(0);"
                onClick={openModal}
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="support-tools-portals-OurOfferings"
        page="support-tools-portals"
       
      />
    </section>
  );
}

export default OurOfferings;
