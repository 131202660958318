import React from "react";

function ToolsNAccelerators() {
  return (
    <section className="section section--tools section--tools-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Tools and Accelerators</h2>
            <div className="container__row">
              <div className="container__col-md-7">
                <p className="container__content">
                  Our continously efforts to build common repeatable solutions
                  as tools ensures providing faster time to market and redueced
                  effor on our engagements. These tools are also available to be
                  distrubted stand-alone, reach out to us to learn more.
                </p>
              </div>
            </div>
          </div>

          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/aem-salesforce.png"
                alt="AEM-Salesforce Connector"
              />
              <a
                href="../../../Hashout-Guide-to-connecting-AEM-and-CRM-for-self-service.pdf"
                target="_blank"
              >
                <h5 className="card__title">AEM-Salesforce Connector</h5>
              </a>
              <p className="card__description">
                Sync content from Salesforce Knowledge to AEM.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/solutions/tools/google-cloud-search.png"
                alt="Google Cloud Search"
              />
              <a
                href="https://hashouttech.com/blog/Understanding-Google-Custom-Search-Engine(CSE)-JSON-API;"
                target="_blank"
              >
                <h5 className="card__title">Google Cloud Search</h5>
              </a>
              <p className="card__description">
                Integration of any website with cost-effective yet powerful
                Google Search APIs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ToolsNAccelerators;
